import { extendTheme } from "@chakra-ui/react";

import components from "./components";
import foundations from "./foundations";

/**
 * We want to deep merge the overrides we made to Chakra UI's components.
 * Example : we added variants to Heading, but want to keep the default styles in parallel.
 */
const deepMergeComponents = extendTheme({
  components,
});

const theme = {
  ...deepMergeComponents,

  /**
   * We don't want to deep-merge the default Chakra UI foundations :
   * we prefer to have full control over the scales of the theme.
   */
  ...foundations,

  styles: {
    global: {},
  },
};

export default theme;
