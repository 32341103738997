import paletteColors from "./paletteColors";

const semanticColors = {
  text: {
    primary: paletteColors.grey[800],
    secondary: paletteColors.grey[700],
    cta: paletteColors.orange[600],
  },

  shine: {
    yellow: "#FEE827"
  }
};

export default semanticColors;
