import { createBreakpoints } from "@chakra-ui/theme-tools";

/**
 * Breakpoints for responsive design
 */
export const lg = "62em";

const breakpoints = createBreakpoints({
  xs: "30em",
  sm: "37em",
  md: "48em",
  lg,
  xl: "80em",
  content: "90em",
  "2xl": "96em",
  "3xl": "100em"
});

export default breakpoints;
