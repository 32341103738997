import { ComponentStyleConfig } from "@chakra-ui/react";

const TagLabel: ComponentStyleConfig = {
  baseStyle: {},

  sizes: {},

  variants: {
    tag: {
      fontSize: "font-16",
      background: "grey.200",
      borderRadius: "60px",
      height: "full",
      padding: "0",
      paddingLeft: "2.5",
      paddingRight: "2.5",
      border: "1px",
      color: "text.secondary",
      fontWeight: "normal",
      lineHeight: "base",
    },
  },

  defaultProps: {
    variant: "tag",
  },
};

export default TagLabel;
