import { ComponentStyleConfig } from "@chakra-ui/react";

const Container: ComponentStyleConfig = {
  baseStyle: {},

  sizes: {},

  variants: {
    default: {
      pl: { base: 4, md: 18, lg: 0 },
      pr: { base: 4, md: 18, lg: 0 },
      maxW: "container.content",
      w: "full",
      overflow: "hidden",
    },
    block: {
      mb: { base: 20, md: 30 },
      minW: "full",
      overflow: "hidden",
      p: 0,
    },
    narrow: {
      pl: { base: 4, md: 18, lg: 0 },
      pr: { base: 4, md: 18, lg: 0 },
      maxW: { base: "100vw", lg: "680px" },
      overflow: "hidden",
      w: "full",
    },
  },

  defaultProps: {
    variant: "default",
  },
};

export default Container;
