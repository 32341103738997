import { ComponentStyleConfig } from "@chakra-ui/react";

const Link: ComponentStyleConfig = {
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
  },

  sizes: {},

  variants: {},

  defaultProps: {},
};

export default Link;
